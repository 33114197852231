import React from "react";
import { Flex, Text, Container } from "@chakra-ui/react";
import { SectionHeading, TextLinkWithArrow } from "../shared";
import ReferenceItem from "./ReferenceItem";

const References = ({ data }) => {
  return (
    <Container maxW="full" centerContent pb={[12, 14, 20]}>
      <Flex flexWrap={["wrap"]} maxW="6xl" w="full">
        <SectionHeading>{data.title}</SectionHeading>
        <Flex
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          alignItems="center"
          alignContent="center"
          flexWrap="wrap"
          pr={[0, 0, 10]}
          pb={[12, 14, 0]}
          textAlign="left"
          justifyContent="flex-start"
        >
          <Text
            color="brand.100"
            fontSize={["sm", "md", "md"]}
            pb={[4]}
            fontWeight="300"
          >
            {data.paragraph.paragraph}
          </Text>
          <TextLinkWithArrow linkType={data.linkType} linkUrl={data.linkUrl}>
            {data.linkText}
          </TextLinkWithArrow>
        </Flex>
        <Flex
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="flex-start"
          alignContent="flex-start"
        >
          {data.referencesItems.map((item, key) => (
            <ReferenceItem key={key} data={item} />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default References;
