import React from "react";
import { Flex, Text, Image, Container, Center } from "@chakra-ui/react";
import { SectionHeading } from "../shared";

const OurNumbers = ({ data }) => {
  return (
    <>
      <Container maxW="full" centerContent py={[12, 14, 20]}>
        <Flex flexWrap={["wrap", "wrap", "nowrap"]} maxW="6xl" w="full">
          <Flex
            flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
            alignItems="center"
            alignContent="center"
            flexWrap="wrap"
            pr={[0, 0, 8]}
            px={[8, 8, 0]}
            pb={[12, 14, 0]}
            textAlign={["center", "center", "left"]}
            justifyContent={["center", "center", "flex-start"]}
          >
            <SectionHeading>{data.title}</SectionHeading>
            <Text
              color="brand.100"
              fontSize={["sm", "md", "md"]}
              pb={[5, 6, 8]}
              fontWeight="300"
            >
              {data.paragraph.paragraph}
            </Text>
          </Flex>
          <Center flex={["1 1 100%", "1 1 100%", "1 1 50%"]} px={[8, 8, 8, 0]}>
            <Image
              src={data.image.file.url}
              maxW="26.5rem"
              w="full"
              h="auto"
              alt={data.title}
            />
          </Center>
        </Flex>
      </Container>
    </>
  );
};

export default OurNumbers;
