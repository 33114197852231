import React from "react";
import { Flex, Image, Text, Heading } from "@chakra-ui/react";

const ModuleCard = ({ data }) => {
  return (
    <Flex
      align="flex-start"
      flexWrap="wrap"
      bgColor="brand.600"
      borderRadius="lg"
      flex={[
        "0 0 16.25rem",
        "0 0 calc(50% - 1.5rem)",
        "0 0 calc(100% / 3 - 1.5rem)",
        "0 0 calc(25% - 1.5rem)",
      ]}
      p={[5, 6]}
      mb={["1.5rem"]}
      mr={["1.5rem"]}
    >
      <Flex w="full" alignItems="center" pb="2">
        <Image src={data.icon.file.url} alt={data.title} w="7" h="auto" />
        <Heading
          as="h3"
          fontSize="lg"
          fontWeight="600"
          textColor="brand.100"
          fontFamily="Montserrat, sans-serif"
          pl={[2, 3]}
          w="calc(100% - 1.75rem)"
        >
          {data.title}
        </Heading>
      </Flex>
      <Text textColor="brand.100" fontSize={["sm"]} fontWeight="300" w="full">
        {data.paragraph.paragraph}
      </Text>
    </Flex>
  );
};

export default ModuleCard;
