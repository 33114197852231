import React from "react";
import { Flex, Text, Image, Container, Center } from "@chakra-ui/react";
import { SectionHeading, TextLinkWithArrow } from "../shared";
import heroBg from "../../../static/consulting-bg.svg";

const Hero = ({ data }) => {
  return (
    <Container
      maxW="full"
      centerContent
      pt={[24, 40]}
      pb={[8, 28]}
      bgImg={["none", "none", "none", "none", `url(${heroBg})`]}
      bgRepeat="no-repeat"
      bgPos={[
        "initial",
        "initial",
        "initial",
        "initial",
        "right -4rem top 1rem",
        "right 1rem",
      ]}
    >
      <Flex flexWrap={["wrap", "wrap", "nowrap"]} maxW="6xl" w="full">
        <Flex
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          alignItems="center"
          alignContent="center"
          flexWrap="wrap"
          px={[8, 8, 0]}
          pb={[12, 14, 0]}
          textAlign={["center", "center", "left"]}
          justifyContent={["center", "center", "flex-start"]}
          order={[0, 0, 1]}
        >
          <SectionHeading as="h1">{data.title}</SectionHeading>
          <Text
            color="brand.100"
            fontSize={["sm", "md", "md"]}
            pb={[4]}
            fontWeight="300"
          >
            {data.paragraph.paragraph}
          </Text>
          <TextLinkWithArrow linkType="External" linkUrl={data.buttonUrl}>
            {data.buttonText}
          </TextLinkWithArrow>
        </Flex>
        <Center
          flex={["1 1 100%", "1 1 100%", "1 1 50%"]}
          px={[8, 8, 8, 0]}
          order={[1, 1, 0]}
        >
          <Image
            src={data.image.file.url}
            maxW="26.5rem"
            w="full"
            h="auto"
            alt={data.image.title}
          />
        </Center>
      </Flex>
    </Container>
  );
};

export default Hero;
