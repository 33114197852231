import React from 'react';
import { Container, Flex, Text } from '@chakra-ui/react';
import { SectionHeading, ButtonPrimary, ButtonSecondary } from '../shared';

const Launchpad = ({ data }) => {
  return (
    <Container maxW='full' centerContent py={[12, 14, 20]} bgColor='brand.600'>
      <Flex maxW='6xl' w='full' justifyContent='center' flexWrap='wrap'>
        <SectionHeading textAlign={['center']}>{data.title}</SectionHeading>
        <Text
          maxW='2xl'
          w='full'
          textAlign={['center']}
          textColor='brand.100'
          fontSize={['sm', 'md']}
          fontWeight='300'
        >
          {data.paragraph.paragraph}
        </Text>
        <Flex w='full' justifyContent='center' flexWrap={['wrap']} pt='6'>
          <ButtonPrimary
            linkType={data.primaryButtonType}
            linkUrl={data.primaryButtonUrl}
          >
            {data.primaryButtonText}
          </ButtonPrimary>
          <ButtonSecondary
            linkType={data.secondaryButtonType}
            linkUrl={data.secondaryButtonUrl}
          >
            {data.secondaryButtonText}
          </ButtonSecondary>
        </Flex>
      </Flex>
    </Container>
  );
};

export default Launchpad;
