import React from "react";
import { Center, Image } from "@chakra-ui/react";

const ReferenceItem = ({ data }) => {
  return (
    <Center
      flex={[
        "0 0 calc(100% / 3 - 0.5rem / 3)",
        "0 0 calc(100% / 3 - 1rem / 3)",
      ]}
      mb={[1, 2]}
      h={[14]}
      bgColor="brand.600"
      borderRadius={["base", "lg"]}
    >
      <Image
        maxW="60%"
        maxH={[10]}
        src={data.image.file.url}
        alt={data.image.title}
      />
    </Center>
  );
};

export default ReferenceItem;
