import React from "react";
import { Container, Flex } from "@chakra-ui/react";
import { SectionHeading, ModulesHeading } from "../shared";
import ModuleCard from "./ModuleCard";

const Modules = ({ data }) => {
  return (
    <Container id="modules" maxW="full" centerContent py={[12, 14, 20]}>
      <Flex maxW="6xl" w="full" justifyContent="flex-start" flexWrap="wrap">
        <SectionHeading textAlign={["center"]} maxWidth="unset">
          {data.title}
        </SectionHeading>
        <ModulesHeading>{data.prelaunchTitle}</ModulesHeading>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap={["nowrap", "wrap"]}
          overflowX={["scroll", "hidden"]}
        >
          {data.prelaunchModules.map((item, key) => (
            <ModuleCard key={key} data={item} />
          ))}
        </Flex>
        <ModulesHeading>{data.setupTitle}</ModulesHeading>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap={["nowrap", "wrap"]}
          overflowX={["scroll", "hidden"]}
        >
          {data.setupModules.map((item, key) => (
            <ModuleCard key={key} data={item} />
          ))}
        </Flex>
        <ModulesHeading>{data.ongoingTitle}</ModulesHeading>
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap={["nowrap", "wrap"]}
          overflowX={["scroll", "hidden"]}
        >
          {data.ongoingModules.map((item, key) => (
            <ModuleCard key={key} data={item} />
          ))}
        </Flex>
      </Flex>
    </Container>
  );
};

export default Modules;
