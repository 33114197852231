import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Cta, Contact, Head } from '../components/shared';
import {
  Hero,
  Launchpad,
  OurNumbers,
  Modules,
  References,
} from '../components/Consulting';

const Consulting = ({ data, location }) => {
  const allData = data.allContentfulConsulting.edges[0].node;

  return (
    <Layout location={location}>
      <Head data={allData.seo} />
      <Hero data={allData.hero} />
      <Launchpad data={allData.launchpad} />
      <OurNumbers data={allData.ourNumbers} />
      <Modules data={allData.modules} />
      <References data={allData.references} />
      <Cta data={allData.cta} />
      <Contact data={allData.contact} />
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allContentfulConsulting {
      edges {
        node {
          hero {
            title
            paragraph {
              paragraph
            }
            buttonText
            buttonUrl
            image {
              title
              file {
                url
              }
            }
          }
          launchpad {
            title
            paragraph {
              paragraph
            }
            primaryButtonText
            primaryButtonUrl
            primaryButtonType
            secondaryButtonText
            secondaryButtonUrl
            secondaryButtonType
          }
          ourNumbers {
            title
            paragraph {
              paragraph
            }
            image {
              file {
                url
              }
            }
          }
          modules {
            title
            prelaunchTitle
            prelaunchModules {
              title
              paragraph {
                paragraph
              }
              icon {
                file {
                  url
                }
              }
            }
            setupTitle
            setupModules {
              title
              paragraph {
                paragraph
              }
              icon {
                file {
                  url
                }
              }
            }
            ongoingTitle
            ongoingModules {
              title
              paragraph {
                paragraph
              }
              icon {
                file {
                  url
                }
              }
            }
          }
          references {
            title
            paragraph {
              paragraph
            }
            linkText
            linkUrl
            linkType
            referencesItems {
              image {
                file {
                  url
                }
                title
              }
            }
          }
          cta {
            title {
              childMarkdownRemark {
                html
              }
            }
            linkUrl
            linkText
          }
          contact {
            title
            text {
              childrenMarkdownRemark {
                html
              }
            }
            eMail
            image {
              file {
                url
              }
            }
          }
          seo {
            pageTitle
            metaDescription {
              metaDescription
            }
            metaImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Consulting;
